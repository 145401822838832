import { Controller } from "@hotwired/stimulus"

export class SearchController extends Controller {
  static get targets() {
    return ["form", "category", "location",
      "query", "categories", "locations", "pills"]
  }

  submit(event) {
    this.formTarget.requestSubmit()
  }

  addLocation(event){
    console.log(event)
    this.addHiddenField(this.formTarget, "locations[]", this.locationTarget.value)
    this.locationTarget.value = ''
    this.formTarget.requestSubmit()
  }

  addCategory(event){
    console.log(event)
    this.addHiddenField(this.formTarget, "categories[]", this.categoryTarget.value)
    this.categoryTarget.value = ''
    this.formTarget.requestSubmit()
  }

  addHiddenField(form, name, value){
    const field = document.createElement('input');
    field.setAttribute('type','hidden');
    field.setAttribute('name', name);
    field.setAttribute('value', value);
    form.appendChild(field);
  }

  removeHiddenField(form, name){
  }

}


