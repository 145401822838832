// If you need to have a button that simply removes container it is placen in, 
// you can assign [data-behavior=container] for the container 
// Note that the use dynamic event binding is complicated due to use of Turbo Streams.

function removeClosestContainer(element) {
  const container = element.closest('[data-behavior=container]');
  if (container) {
    container.style.opacity = '0';
    setTimeout(() => { container.remove(); }, 200);
  };
}

function setupContainerRemoval() {
  window.removeClosestContainer = removeClosestContainer;
  console.log('Container Removal is ready.');
}

["turbo:load", "turbo:render"].forEach(event => {
  document.addEventListener(event, () => {
    setupContainerRemoval();
  })
});
