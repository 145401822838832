import flatpickr from "flatpickr";

const dateFormat = "m/d/Y";

function setupDateLimitOnStoryEndDate() {
  const startDateInput = document.getElementById("story_start_date");
  const endDateInput = document.getElementById("story_end_date");

  if (startDateInput) {
    const endDatePicker = flatpickr(endDateInput, { dateFormat, allowInput: true });

    const parsedInitialDate = flatpickr.parseDate(startDateInput.value, dateFormat);
    if(parsedInitialDate){
      endDatePicker.set("minDate", parsedInitialDate);
    }

    flatpickr(startDateInput, {
      dateFormat,
      allowInput: true,
      onChange: function (selectedDates, dateStr) {
        const parsedDate = flatpickr.parseDate(dateStr, dateFormat);
        endDatePicker.set("minDate", parsedDate);
      },
    });
  }
}

["turbo:load", "turbo:render"].forEach((event) => {

  document.addEventListener(event, () => {
    flatpickr('[data-behavior="date_picker"]', {
      dateFormat,
      allowInput: true,
    });

    setupDateLimitOnStoryEndDate();
  });
});
