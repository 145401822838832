import { Application } from "@hotwired/stimulus"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

// Load controllers..


// Load server side component controllers
// We might try autoloading of server side component controllers 
// https://github.com/palkan/view_component-contrib#organizing-components-or-sidecar-pattern-extended
import { SearchController } from "../../components/story_search/search_bar/_controller"
Stimulus.register("search", SearchController)


// import { ImpactMapController } from "../../components/story_search/search_bar/_controller"
// Stimulus.register("search", SearchController)